import React from "react";
import Layout from "../components/Layout";

const Error = () => {
  return (
    <Layout title="404" page="404" url="/404">
      <h1 className="font-h1">404 Page Not Found! :(</h1>
    </Layout>
  );
};

export default Error;
